import { getAuthHeader } from "@src/app/superagent";
import { environmentConfig } from "@src/appV2/environment";
import { logApiFailureEvent } from "@src/lib/analytics";
import { VerificationPreferences } from "@src/lib/interface";
import request from "superagent";

export const fetchFacilityDetails = async (facilityId: string) => {
  try {
    const { body } = await request
      .get(
        `${environmentConfig.REACT_APP_BASE_API_URL}/facilityProfile/verificationPreferences?facilityId=${facilityId}`
      )
      .retry(1, (err) => {
        if (!err) {
          return false;
        }
        return true;
      })
      .set(await getAuthHeader());
    return body;
  } catch (error) {
    logApiFailureEvent(error);
    return Promise.resolve({});
  }
};

export const fetchFacilitiesVerificationPreferences = async (
  facilityIds: string[]
): Promise<{ data?: VerificationPreferences[] }> => {
  try {
    const { body } = await request
      .post(`${environmentConfig.REACT_APP_BASE_API_URL}/facilityProfile/verificationPreferences`)
      .set(await getAuthHeader())
      .send({ facilityIds });
    return body;
  } catch (error) {
    logApiFailureEvent(error);
    return Promise.resolve({});
  }
};
