import { getAuthHeader } from "@src/app/superagent";
import { environmentConfig } from "@src/appV2/environment";
import axios, { InternalAxiosRequestConfig } from "axios";
import AxiosRetry from "axios-retry";
import qs, { IStringifyOptions } from "qs";
export const retryOnceParams = [1, (err: Error) => !!err] as const;

export const getAxiosWithInterceptors = (
  baseURL = environmentConfig.REACT_APP_BASE_API_URL,
  opts: { qsStringifyOpts?: IStringifyOptions } = {}
) => {
  const apiClient = axios.create({
    baseURL,
    paramsSerializer: (params) => qs.stringify(params, opts?.qsStringifyOpts),
  });

  apiClient.interceptors.request.use(
    async (config) => {
      const authHeader = await getAuthHeader();
      return {
        ...config,
        headers: { ...config.headers, ...authHeader },
      } as InternalAxiosRequestConfig;
    },
    (error) => Promise.reject(error)
  );

  apiClient.interceptors.response.use(
    (response) => response,
    /**
     * @deprecated
     * FIXME - there is a fair amount of risky code patterns here, so we are adding
     * defensive ambiguous types to minimize surprises in code changes.
     * The error could be an Axios error, or it could be any error.
     * This appears to be causing bugs like:
     * https://linear.app/clipboardhealth/issue/FEF-716/pull-out-the-actual-error-in-axios-interceptor
     */
    async (error: Record<string, any | undefined> | undefined) => {
      const originalRequest: Record<string, any | undefined> = error?.config ?? {};
      const response: Record<string, any | undefined> | undefined = error?.response;
      if ([401, 403].includes(response?.status) && !originalRequest?._retry) {
        originalRequest._retry = true;
        const authHeader = await getAuthHeader();
        originalRequest.headers = { ...originalRequest.headers, ...authHeader };
        return apiClient(originalRequest);
      }
      return Promise.reject(error);
    }
  );

  AxiosRetry(apiClient, {
    retries: 1,
    retryDelay: (retryCount) => retryCount * 1000,
  });

  return apiClient;
};
