import { NodeEnvironment, environmentConfig } from "@src/appV2/environment";
import { logEvent } from "@src/appV2/lib/analytics";
import { UXCamSdk } from "@src/appV2/lib/UXCamSdk/UXCamSdk";
import { useGetWorker } from "@src/appV2/Worker/api/useGetWorker";
import { createSegmentInstance } from "@src/lib/analytics";
import { useEffect, useRef } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";

import { USER_EVENTS, UserEvent } from "../../constants/userEvents";
import { getRouterPathInfo } from "../routing/constant";
import { GlobalRouterPath } from "../routing/constant/globalRoute";

interface RouteDetails {
  referrer: string;
  path: string;
  title: string;
  search: string;
  url: string;
  shiftId?: string;
}
export function useAnalyticsRouterTagging() {
  const { pathname, search } = useLocation();
  const match = useRouteMatch(pathname);
  const previousPathnameRef = useRef("");

  // disabling the query since we want to only listen for changes here, without fetching
  const { data: worker } = useGetWorker({ enabled: false });
  const { email } = worker ?? {};

  useEffect(() => {
    if (process.env.NODE_ENV !== NodeEnvironment.TEST) {
      const pathInfo = getRouterPathInfo(match?.path as string);
      const screenName = pathInfo?.name || pathname;

      UXCamSdk.tagScreenName(screenName);
    }
  }, [pathname, match?.path]);

  useEffect(() => {
    if (!window["analytics"]) {
      /**
       * FIXME - should we await for createSegmentInstance?
       */
      createSegmentInstance({
        key: environmentConfig.REACT_APP_SEGMENT_KEY,
        androidKey: environmentConfig.REACT_APP_SEGMENT_ANDROID_KEY,
        iosKey: environmentConfig.REACT_APP_SEGMENT_IOS_KEY,
        webKey: environmentConfig.REACT_APP_SEGMENT_WEB_KEY,
      });
    }

    const rootPaths = Object.values(GlobalRouterPath);
    const locationIsValid = rootPaths.some((path) => pathname.startsWith(path));

    if (!locationIsValid) {
      return;
    }

    const routeDetails: RouteDetails = {
      referrer: pathname === previousPathnameRef.current ? "" : previousPathnameRef.current,
      path: pathname,
      title: document.title,
      search: search,
      url: document.URL,
    };

    if (!pathname.includes("loading")) {
      previousPathnameRef.current = pathname;

      /**
       * FIXME: Convert to constants from DeprecatedGlobalAppV1Paths.UNVERIFIED_SHIFTS
       */
      const myShiftsRegex = /\/home\/myShifts\/\w+/;
      const myShiftsUnverified = "/home/myShifts/unverifiedShifts";

      const myShiftRegex = /\/home\/myShift\/\w+/;
      const myShiftRatingRegex = /\/home\/myShift\/\w+\/rating$/;
      const myShiftSignatureRegex = /\/home\/myShift\/\w+\/signature$/;
      const myOngoingShiftRegex = /\/home\/myShift\/ongoing\/\w+/;

      const openShiftsRegex = /\/home\/openShifts\/\w+/;

      if (myShiftsRegex.test(pathname)) {
        /**
         * FIXME - this level of specific detail is not logical for this code.
         * The route matching functionality is not good.
         */
        let name: UserEvent = USER_EVENTS.VIEWED_MY_SHIFTS;

        if (pathname === myShiftsUnverified) {
          name = USER_EVENTS.VIEWED_UNVERIFIED_SHIFTS;
        } else {
          const shiftId = pathname.substring(pathname.lastIndexOf("/"));
          routeDetails.shiftId = shiftId;
        }

        logEvent(name, routeDetails);
      } else if (myShiftRegex.test(pathname)) {
        let name = "Viewed My Shift";

        if (myOngoingShiftRegex.test(pathname)) {
          name = "Viewed Ongoing Shift";
        } else if (myShiftRatingRegex.test(pathname)) {
          name = "Viewed Shift Rating";
        } else if (myShiftSignatureRegex.test(pathname)) {
          name = "Viewed Shift Signature";
        }

        const shiftId =
          myShiftRatingRegex.test(pathname) || myShiftSignatureRegex.test(pathname)
            ? pathname.split("/")[3]
            : pathname.substring(pathname.lastIndexOf("/") + 1);

        logEvent(name, {
          ...routeDetails,
          shiftId,
        });
      } else if (openShiftsRegex.test(pathname)) {
        const name = USER_EVENTS.SHIFT_OPENED;
        const date = pathname.substring(pathname.lastIndexOf("/") + 1);

        logEvent(name, {
          ...routeDetails,
          date,
          email,
        });
      }
    }
  }, [email, pathname, search]);
}
