import { environmentConfig } from "@src/appV2/environment";
import request from "superagent";

import { retryOnceParams } from "./utils";
import { getAuthHeader } from "../superagent";

export async function resyncAccountWithStripe(agentId: string): Promise<void> {
  await request
    .post(`${environmentConfig.REACT_APP_PAYMENTS_API_URL}/accounts/${agentId}/resync-from-stripe`)
    .retry(...retryOnceParams)
    .set(await getAuthHeader())
    .then(({ body }) => body)
    .catch((err) => {
      console.error(err);
    });
}
