import { getAuthHeader } from "@src/app/superagent";
import { environmentConfig } from "@src/appV2/environment";
import request from "superagent";

export enum REFERENCE_REQUIREMENT_STATE {
  COMPLETE = "complete",
  IN_PROGRESS = "in_progress",
  NOT_STARTED = "not_started",
}

export interface IReferenceFormResponse {
  status: REFERENCE_REQUIREMENT_STATE;
  count: number;
  minVerifiedReferenceRequired: number;
}

export const fetchReferences = async (): Promise<IReferenceFormResponse> => {
  const { body } = await request
    .get(`${environmentConfig.REACT_APP_WORKER_SERVICE_URL}/references/status`)
    .set(await getAuthHeader());
  return body;
};
