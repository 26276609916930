import { getAuthHeader } from "@src/app/superagent/index";
import { environmentConfig } from "@src/appV2/environment";
import { logApiFailureEvent } from "@src/lib/analytics/src";
import request from "superagent";

export type ExclusionForList = {
  createdAt: string;
  agent: {
    userId: string;
    name: string;
  };
  notes: string;
  reason: string;
  actionBy: "AGENT" | "FACILITY";
  _id: string;
};

interface ListExclusionRequest {
  facilityId: string;
  agentId?: string;
  pageSize?: number;
}

export const getExclusions = async (
  requestBody: ListExclusionRequest
): Promise<ExclusionForList[]> => {
  try {
    const response = await request
      .get(`${environmentConfig.REACT_APP_BASE_API_URL}/exclusion`)
      .set(await getAuthHeader())
      .query(requestBody);
    return response.body;
  } catch (err) {
    logApiFailureEvent(err);
    throw err;
  }
};
