import { getAxiosWithInterceptors } from "@src/app/api/utils";
import { TripTrackingTrigger } from "@src/app/locationTracking/locationTracking.types";
import { APP_V2_APP_EVENTS, logError, logEvent } from "@src/appV2/lib/analytics";
import { AxiosInstance, isAxiosError } from "axios";

import {
  LocationTrackingMode,
  LocationTrackingModeEnum,
  StartHyperTrackShiftResponse,
} from "./cbh-main-api.types";

export class CbhMainApi {
  constructor(private readonly axios: AxiosInstance) {}

  async getLocationTrackingMode(workerId: string, platform: string): Promise<LocationTrackingMode> {
    const response = await this.axios.get(
      `/urgentShifts/location-tracking-mode?workerId=${workerId}&platform=${platform}`
    );
    return response?.data;
  }

  async startHyperTrackTrip(params: {
    shiftId: string;
    workerId: string;
    trigger: TripTrackingTrigger;
    trackingMode: LocationTrackingModeEnum;
  }): Promise<StartHyperTrackShiftResponse> {
    const { shiftId, workerId, trigger, trackingMode } = params;

    const loggerMetadata = {
      shiftId,
      workerId,
      trigger,
      trackingMode,
    };

    try {
      const {
        data: { isSuccess, reason },
      } = await this.axios.post<StartHyperTrackShiftResponse>(`/urgentShifts/track/${shiftId}`);

      if (isSuccess) {
        logEvent(APP_V2_APP_EVENTS.HYPER_TRACK_TRIP_TRACKING_STARTED, {
          ...loggerMetadata,
          reason,
        });
      } else {
        logEvent(APP_V2_APP_EVENTS.HYPER_TRACK_TRIP_TRACKING_FAILED, {
          metadata: {
            ...loggerMetadata,
            reason,
          },
        });
      }

      return { isSuccess };
    } catch (error) {
      const errorMetadata = isAxiosError(error)
        ? {
            message: error.message,
            name: error.name,
            code: error.code,
            config: error.config,
            response: error.response
              ? {
                  status: error.response.status,
                  statusText: error.response.statusText,
                  headers: error.response.headers,
                  data: error.response.data,
                }
              : null,
          }
        : {};

      logError(APP_V2_APP_EVENTS.HYPER_TRACK_TRIP_TRACKING_FAILED, {
        error,
        metadata: { ...loggerMetadata, errorMetadata },
      });

      return { isSuccess: false };
    }
  }
}

const axios = getAxiosWithInterceptors();

export const cbhMainApi = new CbhMainApi(axios);
